<template>
    <div class="details centebox">
        <el-card>
            <div>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
                    <el-breadcrumb-item>申报详情</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <h1>
                {{ contentData.title }}
                <p class="datasource">
                    <span>发布日期：{{ clob.createTime | formatDate }}</span>
                    <span>来源：人才办</span>
                </p>
            </h1>
            <div class="pages_content" v-html="clob.content"></div>

            <el-button type="primary" @click="back">返回</el-button>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            contentData: "",
            clob: {},
            Columnname: ''
        };
    },

    //用于数据初始化
    created() {
        this.getData(); //获取详情
    },

    //用于存放所有的事件方法集合
    methods: {
        getData() {
            let self = this;
            this.$axios.get("/policy", {
                params: {
                    id: self.$route.query.id
                }
            }).then(function (res) {
                self.contentData = res.data.data;
                self.clob = res.data.data.clob;
            }).catch(function (error) {
                console.error(error);
            });
        },

        back() {
            this.$router.back()
        },

        request() {
            let newPage = this.$router.resolve({
                name: 'EntryStoma',
            })
            window.open(newPage.href, '_blank')
        },
    }
};
</script>

<style lang="less" scoped>
.details {
    background: #ffffff;
    width: 100%;
    min-width: 880px;

    h1 {
        padding: 16px 0 0 0;
        border-bottom: 1px #ddd solid;

        p {
            padding: 0;
            margin: 0;
            height: 65px;
            line-height: 70px;

            span {
                font-size: 16px;
                font-weight: 500;
                padding: 0 15px;
            }
        }
    }

    .datasource span {
        padding: 0 10px
    }

    .pages_content {
        padding: 20px;
        text-align: left;
        line-height: 36px;
        text-indent: 2em;
        word-wrap: break-word;
        word-break: normal;
        min-height: 200px
    }
}

.heasd {
    height: 44px;
    border-bottom: 1px #ddd solid;
    text-align: left;
    line-height: 40px;
    padding: 6px 0 0 16px;
    color: #2380d7;
    font-weight: bold;
}
</style>